"use client";

import React from "react";
import {
  ConfigurableForm,
  FormFullName,
  LOGIN_CONFIGURATION,
  PageWithLayout,
} from "@shesha-io/reactjs";

interface IProps {}

const Login: PageWithLayout<IProps> = () => (
  <ConfigurableForm
    mode={"edit"}
    formId={{
      module: "Shesha.Enterprise",
      name: "agrierp-login"
    }}
  />
);

export default Login;